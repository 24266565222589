import {useEffect, useRef, useState} from "react";
import "./typingStyle.scss"

const FORWARD = "forward"
const BACKWARD = "backward"


let pauseCounter = 0
export const useTyping = (string, speed, pause, cursor = false) => {
    const [wordIndex, setWordIndex] = useState(-1);
    const [word, setWord] = useState("");
    const letterIndex = useRef(0)
    const interval = useRef(0)
    const direction = useRef(BACKWARD)
    const [paused, setPaused] = useState(false);

    useEffect(() => {

        setPaused(false)

        const typing = () => {
            if (letterIndex.current >= string[wordIndex].length) {
                direction.current = BACKWARD
                pauseCounter = pause

                setPaused(true)
                return
            }
            const segment = string[wordIndex].split("")
            setWord(word.concat(segment[letterIndex.current]))
            letterIndex.current++
        }
        const backspace = () => {
            if (letterIndex.current === 0) {
                const isOnLastWord = wordIndex === string.length - 1;

                setWordIndex(!isOnLastWord ? wordIndex + 1 : 0);
                direction.current = FORWARD;

                return
            }

            const segment = word.slice(0, word.length - 1);
            setWord(segment);
            letterIndex.current = word.length - 1;
        }

        interval.current = setInterval(() => {
            if (pauseCounter > 0) {
                pauseCounter--
            
                return
            }

            if (direction.current === FORWARD) {
                typing()
            } else {
                backspace()
            }
        }, speed)

        return () => {
            clearInterval(interval.current)
        }
    }, [speed, string, word, wordIndex, pause])

    return <span className="typing-word">{word} {(!paused && cursor && word.length > 0) &&
        <span className="blink-cursor"></span>} </span>

}
