import "./skillsStyle.scss";
import SkillIcon from "./SkillIcon";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import SkillService from "../../services/skillService";
import {setSkills} from "../../store/skillSlice";
import UtilsService from "../../services/utilsService";

function Skills() {
	const dispatch = useDispatch()
	const {skills} = useSelector(state => state.skillsStore)
	useEffect(() => {
		SkillService.getAll().then((res) => {
			dispatch(setSkills(res.data))
		});
	}, []);
	return (
	  <section className="skills-wrapper container">
		  <h2>My Skills</h2>
		  <div className="skills-icon">
			  {skills.primary?.map((el, index) => {
				  return (
					<SkillIcon name={el.name} key={index}>
						<img src={UtilsService.realFileURL(el.icon)} alt={el.name}/>
					</SkillIcon>
				  );
			  })}
		  </div>
		  <h2>Other skills</h2>
		  <div className="skills-icon">
			  {skills.secondary?.map((el, index) => {
				  return (
					<SkillIcon name={el.name} key={index}>
						<img src={UtilsService.realFileURL(el.icon)} alt={el.name}/>
					</SkillIcon>
				  );
			  })}
		  </div>
	  </section>
	);
}

export default Skills;
