import React, {useRef} from "react";

import "./homeStyle.scss";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import Skills from "../../components/Skills/Skills";
import HomeContact from "../../components/HomeContact/HomeContact";
import About from "../../components/About/About";
import WhatIDo from "../../components/WhatIDo/Services";
import Cta from "../../components/Cta/Cta";
import Projects from "../../components/Projects/Projects";
import Progress from "../../components/Progress/Progress";

function Home() {
	const scrollTo = useRef();

	function goTo() {
		window.scrollTo({
			top: scrollTo.current.offsetTop,
			behavior: "smooth",
		});
	}

	return (
	  <>
		  <HomeHeader goTo={goTo}/>
		  <About/>
		  <WhatIDo/>
		  <Cta goTo={goTo}/>
		  <Skills/>
		  <Projects/>
		  <Progress/>
		  <HomeContact scrollTo={scrollTo}/>
	  </>
	);
}

export default Home;
