import axios from "axios";

class ProjectService {
  static getAll = () => axios.get("/api/project/getAll");
  static delete = (id) => {
    return axios.delete("/api/project/delete/" + id);
  };
  static getImage = () => {
    return axios.get("/api/project/test");
  };

  static update = (body) => {
    return axios.post("/api/project/update", body);
  };
}

export default ProjectService;
