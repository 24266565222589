import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../../store/modalSlice";
import Modal from "../../components/Modal/Modal";
import ProjectForm from "./ProjectForm";
import { useEffect, useMemo, useState } from "react";
import ProjectService from "../../services/projectService";
import { setProject } from "../../store/projectSlice";
import ProjectCard from "./ProjectCard";
import "./project.scss";
import EditProject from "./EditProject";

function Project() {
  const dispatch = useDispatch();
  const { showModal } = useSelector((state) => state.modalStore);
  const { projects } = useSelector((state) => state.projectStore);
  const [editForm, setEditForm] = useState(false);
  const [addForm, setAddForm] = useState(false);

  const [editedProject, setEditedProject] = useState({});

  useEffect(() => {
    ProjectService.getAll()
      .then((res) => {
        dispatch(setProject(res.data));
      })
      .catch((reason) => {
        console.log(reason);
      });
  }, []);

  useEffect(() => {
    if (!showModal) {
      setEditForm(false);
      setAddForm(false);
    }
  }, [showModal]);

  const showEditForm = (project) => {
    setEditForm(true);
    setEditedProject(project);
  };

  const projectLayout = useMemo(() => {
    let layout = projects.map((el, index) => {
      return (
        <ProjectCard key={index} project={el} showEditForm={showEditForm} />
      );
    });
    return layout;
  }, [projects]);

  return (
    <div className="adminProject-wrapper">
      <div className="dashboard-title">
        <h3>Project</h3>
        <div>
          <button
            className="btn btn-success"
            onClick={() => {
              dispatch(setShowModal());
              setAddForm(true);
            }}
          >
            Add project
          </button>
        </div>
      </div>
      <div className="dashboard-content">{projectLayout}</div>
      {showModal && (
        <Modal>
          {editForm && <EditProject project={editedProject} />}
          {addForm && <ProjectForm />}
        </Modal>
      )}
    </div>
  );
}

export default Project;
