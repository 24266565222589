import {createSlice} from "@reduxjs/toolkit";

const SkillSlice = createSlice({
    name: "skill",
    initialState: {
        skills: {}
    },
    reducers: {
        setSkills: (state, action) => {
            state.skills = action.payload
        },
        addSkill: (state, action) => {
            let {payload} = action
            let copySkills = {...state.skills}
            copySkills[payload.priority] = [...copySkills[payload.priority], payload]
            state.skills = copySkills
        },
        updateSkill: (state, action) => {
            let {payload} = action
            let copySkills = {...state.skills}
            let foundIndex = null
            copySkills[payload.priority].find((el, index) => {
                foundIndex = index
                return el._id === payload._id
            })
            copySkills[payload.priority][foundIndex] = payload
            state.skills = copySkills
        }
    }
})

export const {setSkills, addSkill, updateSkill} = SkillSlice.actions
export default SkillSlice.reducer