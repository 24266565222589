import React from "react";
import {useDispatch} from "react-redux";
import {setMounted} from "../../store/modalSlice";
import {useRef, useState} from "react";
import AdminService from "../../services/adminService";
import ProjectService from "../../services/projectService";

import {technology} from "./technology";
import {updateProject} from "../../store/projectSlice";

function EditProject({project}) {
    const fileRef = useRef();
    const dispatch = useDispatch();
    const [inputData, setInputData] = useState(project);

    const onSubmitHandler = (e) => {
        e.preventDefault();
        let fd = new FormData();
        fd.append("data", JSON.stringify(inputData));
        
        ProjectService.update(fd).then((res) => {
            dispatch(setMounted(false))
            dispatch(updateProject(inputData))
        });

    };

    const onInputHandler = (e) => {
        let copyInputData = {...inputData};
        copyInputData[e.target.name] = e.target.value;
        setInputData(copyInputData);
    };

    const onCheckedData = (e) => {
        let copyInputData = {...inputData};
        !copyInputData.hasOwnProperty("technology") &&
        (copyInputData.technology = []);
        if (e.target.checked) {
            copyInputData.technology = [...copyInputData.technology, e.target.name];
        } else {
            copyInputData.technology = copyInputData.technology.filter(
                (el) => el !== e.target.name
            );
        }
        setInputData(copyInputData);
    };

    const handleFile = (e) => {
        setInputData({...inputData, thumbnail: e.target.files[0]});
    };
    return (
        <div>
            <form className="adminProject-form" onSubmit={onSubmitHandler}>
                <div className="form-group">
                    <input
                        name="project_title"
                        id="title"
                        type="text"
                        placeholder=""
                        onInput={onInputHandler}
                        value={inputData.project_title}
                    />
                    <label htmlFor="title">Project title</label>
                </div>
                <div className="form-group">
                    <input
                        name="github_url"
                        id="github"
                        type="url"
                        placeholder=""
                        onInput={onInputHandler}
                        value={inputData.github_url}
                    />
                    <label htmlFor="github">GitHub</label>
                </div>
                <div className="form-group">
                    <input
                        name="live_url"
                        id="preview"
                        type="url"
                        placeholder=""
                        onInput={onInputHandler}
                        value={inputData.live_url}
                    />
                    <label htmlFor="preview">Live preview</label>
                </div>
                <div className="form-group">
          <textarea
              name="description"
              id="description"
              placeholder=""
              onInput={onInputHandler}
              value={inputData.description}
          />
                    <label htmlFor="description">Description</label>
                </div>
                <div className="technology">

                    {technology.map((el, index) => {
                        return (
                            <div key={el + index} className="form-checkbox">
                                <input
                                    type="checkbox"
                                    name={el}
                                    id={el + index}
                                    onInput={onCheckedData}
                                    defaultChecked={project.technology.includes(el)}

                                />
                                <label htmlFor={el + index}>{el}</label>
                            </div>
                        );
                    })}
                </div>
                <div className="form-btn form-group-btn">
                    <button
                        className="btn btn-warning"
                        type="button"
                        onClick={() => fileRef.current.click()}
                    >
                        Set thumbnail
                    </button>
                    <input ref={fileRef} type="file" onInput={handleFile}/>
                    <button className="btn btn-danger">Save</button>
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => {
                            dispatch(setMounted(false));
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}

export default EditProject;
