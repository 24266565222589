export const technology = [
	"HTML",
	"CSS",
	"SASS",
	"Bootstrap",
	"JS",
	"React",
	"PHP",
	"MySQL",
	"NodeJS",
	"MongoDB",
	"WordPress",
];