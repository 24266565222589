import axios from "axios";

class UserService {
    static login = (body) => {
        return axios.post("api/users/login", body)
    }
    static getUsers = () => {
        return axios.get("api/users")
    }

    static getUserInfo = () => {
        return axios.get("api/users/info")
    }
}

export default UserService