import "./aboutStyle.scss"
import Social from "../Social/Social";
import {useSelector} from "react-redux";
import UtilsService from "../../services/utilsService";

function About() {
    const {user} = useSelector(state => state.userStore)
    return (
        <section className="about">
            <article className="container">
                <h2>Who Am I</h2>
            </article>
            <article className="about-row container">
                <div className="about-row-profile">
                    <img src={UtilsService.realFileURL(user.profileImage)} alt="Dejan Zivkovic"/>
                </div>
                <div className="about-row-text">
                    <h3>I`m <span>{user.first_name} {user.last_name}</span></h3>
                    <p>{user.about}</p>
                    <div className="left">
                        <p><span>Full name:</span> {user.first_name} {user.last_name}</p>
                        <p><span>Experience:</span> {user.experience} years</p>
                    </div>
                    <div className="right">
                        <p><span>Age:</span> {user.age} years old</p>
                        <p><span>Email:</span> {user.email}</p>
                        <Social/>
                    </div>
                    <div className="social"></div>
                </div>
            </article>

        </section>
    );
}

export default About;