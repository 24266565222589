import React from 'react';

function SkillIcon({name, children}) {
    return (
        <div>
            {children}
            <p>{name}</p>
        </div>
    );
}

export default SkillIcon;