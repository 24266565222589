const adminRoutes = {
	DASHBOARD: {
		route: "dashboard",
	},
	MY_INFO: {
		route: "/dashboard",
		name: "My Info",
		index: true,
	},
	SERVICES: {
		route: "services",
		name: "Service",
	},
	PROJECTS: {
		route: "project",
		name: "Projects",
	},
	SKILLS: {
		route: "skills",
		name: "Skills",
	},
};

export default adminRoutes;
