import React from 'react';
import {FaFacebookF, FaGithub, FaLinkedinIn, FaSkype, FaViber,} from "react-icons/fa"
import "./socialStyle.scss"

function Social() {
    return (
        <div className="social-wrapper">
            <a href="https://www.facebook.com/profile.php?id=1207011299"><FaFacebookF/></a>
            <a href="https://www.linkedin.com/in/dejan-zivkovic-8b6530126/"><FaLinkedinIn/></a>
            <a href="https://github.com/zile028"><FaGithub/></a>
            <a href="viber://chat?number=00381606374003"><FaViber/></a>
            <a href="https://join.skype.com/invite/LWlYuqntn1vT"><FaSkype/></a>
        </div>
    );
}

export default Social;