import React, {useState} from "react";
import AdminService from "../../services/adminService";
import {setUser} from "../../store/userSlice";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {setMounted} from "../../store/modalSlice";

function EditFrom({user}) {
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({...user});
    const onInputHandle = (e) => {
        let copyUserInfo = {...userInfo};
        copyUserInfo[e.target.name] = e.target.value;
        setUserInfo(copyUserInfo);
    };

    const onSubmitHandle = (e) => {
        e.preventDefault();
        AdminService.updateUserInfo(userInfo).then((res) => {
            if (res.status === 200) {
                dispatch(setUser(userInfo));
                toast.info("Your info is successfully updated.");
                dispatch(setMounted(false));
            }
        });
    };
    return (
        <form className="form" onSubmit={onSubmitHandle}>
            <div className="form-group form-group-fn">
                <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder=""
                    value={userInfo.first_name}
                    onInput={onInputHandle}
                />
                <label htmlFor="first_name">First name</label>
            </div>
            <div className="form-group form-group-ln">
                <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    placeholder=""
                    value={userInfo.last_name}
                    onInput={onInputHandle}
                />
                <label htmlFor="last_name">Last name</label>
            </div>
            <div className="form-group form-group-email">
                <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder=""
                    value={userInfo.email}
                    onInput={onInputHandle}
                />
                <label htmlFor="email">Email</label>
            </div>
            <div className="form-group form-group-exp">
                <input
                    id="experience"
                    name="experience"
                    type="number"
                    placeholder=""
                    value={userInfo.experience}
                    onInput={onInputHandle}
                />
                <label htmlFor="experience">Experience</label>
            </div>
            <div className="form-group form-group-age">
                <input
                    id="age"
                    name="age"
                    type="number"
                    placeholder=""
                    value={userInfo.age}
                    onInput={onInputHandle}
                />
                <label htmlFor="age">Age</label>
            </div>
            <div className="form-group form-group-about">
        <textarea
            id="about"
            name="about"
            placeholder=""
            value={userInfo.about}
            onInput={onInputHandle}
        />
                <label htmlFor="about">About</label>
            </div>
            <div className="form-btn form-group-btn">
                <button className="btn btn-danger">Save</button>
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => {
                        dispatch(setMounted(false));
                    }}
                >
                    Cancel
                </button>
            </div>
        </form>
    );
}

export default EditFrom;
