import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./loginStyle.scss";
import userService from "../../services/userService";
import {useDispatch} from "react-redux";
import {login, setUser} from "../../store/userSlice";

function Login() {
    const [inputData, setInputData] = useState({});
    const redirect = useNavigate()
    const dispatch = useDispatch()

    function onInputHandler(e) {
        let copyInputData = {...inputData};
        copyInputData[e.target.name] = e.target.value;
        setInputData(copyInputData);
    }

    function onSubmitHandler(e) {
        e.preventDefault();
        userService.login(inputData).then((res) => {
            if (res.status === 210) {
                console.log(res.data);
            } else {
                dispatch(login(res.data.user))
                localStorage.setItem("token", JSON.stringify(res.data.token))
                redirect("/dashboard")
            }
        });
    }

    return (
        <section className="login-wrapper">
            <article className="container">
                <form onSubmit={onSubmitHandler}>
                    <div className="form-group">
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder=""
                            onInput={onInputHandler}
                        />
                        <label htmlFor="username">Username</label>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            id="password"
                            name="password"
                            placeholder=""
                            onInput={onInputHandler}
                        />
                        <label htmlFor="password">Password</label>
                    </div>

                    <div>
                        <button className="btn btn-danger">Login</button>
                    </div>
                </form>
            </article>
        </section>
    );
}

export default Login;
