import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {},
        isLogin: false
    },
    reducers: {
        login: (state, action) => {
            state.isLogin = true
            localStorage.setItem("userLogin", JSON.stringify(action.payload));
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        logout: (state) => {
            state.isLogin = false
            localStorage.removeItem("userLogin");
            localStorage.removeItem("token");
        }
    }
})

export const {setUser, login, logout} = userSlice.actions
export default userSlice.reducer