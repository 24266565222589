import React from 'react';
import UtilsService from "../../services/utilsService";
import {FaGithub, FaGlobe} from "react-icons/fa";

function Project({project}) {
    return (
        <div className="project-card">
            <div className="thumbnail">
                <div>
                    <img src={UtilsService.realFileURL(project.thumbnail)} alt=""/>
                    <ul className="links">
                        {project.github_url &&
                            <li><a className="btn btn-sm btn-warning" href={project.github_url}><FaGithub/></a></li>
                        }
                        {project.live_url &&
                            <li><a className="btn btn-sm btn-warning" href={project.live_url}>
                                <FaGlobe/>
                            </a></li>
                        }
                    </ul>
                </div>
            </div>
            <div className="text">
                <h3>{project.project_title}</h3>

                {project.technology.length > 0 &&
                    <ul className="technology">
                        {project.technology.map((el, index) => <li key={index}>{el}</li>)}
                    </ul>
                }


            </div>
        </div>
    );
}

export default Project;