import React from 'react';
import "./projects.scss"
import {useSelector} from "react-redux";
import Project from "./Project";

function Projects() {
    const {projects} = useSelector(state => state.projectStore)
    return (
        <section className="projects-wrapper">
            <article className="title container">
                <h2>My project</h2>
            </article>
            <article className="projects-wrapper-cards container">
                {projects.map((el, index) => {
                    if (el.favorite) {
                        return <Project project={el} key={index}/>
                    }
                })}
            </article>
        </section>
    );
}

export default Projects;