import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "./navigation.scss";
import {logout} from "../../store/userSlice";

function Navigation() {
	const {isLogin} = useSelector((store) => store.userStore);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onClickHandler = () => {
		dispatch(logout());
		navigate("/");
	};

	const navLayout = () => {
		return isLogin ? (
		  <>
			  <li>
				  <NavLink to="/">Home</NavLink>
			  </li>
			  <li onClick={onClickHandler}>Logout</li>
			  <li>
				  <NavLink to={"dashboard"}>Dashboard</NavLink>
			  </li>
		  </>
		) : (
		  <li>
			  <NavLink to={"login"}>Login</NavLink>
		  </li>
		);
	};

	return (
	  <nav className="navbar">
		  <div className="brand"></div>
		  <div className="menu-list">
			  <ul>
				  {/*<li>*/}
				  {/*  <NavLink to={"/"}>Home</NavLink>*/}
				  {/*</li>*/}
				  {/*<li>*/}
				  {/*  <NavLink to={"/about"}>About</NavLink>*/}
				  {/*</li>*/}
				  {/*<li>*/}
				  {/*  <NavLink to={"/#skills"}>Skills</NavLink>*/}
				  {/*</li>*/}
				  {/*<li>*/}
				  {/*  <NavLink to={"/project"}>Project</NavLink>*/}
				  {/*</li>*/}
				  {/*<li>*/}
				  {/*  <NavLink to={"/progress"}>Progress</NavLink>*/}
				  {/*</li>*/}
				  {/*<li>*/}
				  {/*  <NavLink to={"/contact"}>Contact</NavLink>*/}
				  {/*</li>*/}
				  {navLayout()}
			  </ul>
		  </div>
	  </nav>
	);
}

export default Navigation;
