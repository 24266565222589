import React from 'react';
import {useTyping} from "../../hooks/useTyping";
import "./homeHeaderStyle.scss"
import {useSelector} from "react-redux";

function HomeHeader({goTo}) {
    const {user} = useSelector(state => state.userStore)
    const typing = useTyping([user.first_name, user.last_name], 100, 10)
    return (
        <>
            <header className="header-wrapper">
                <div className="text">
                    <p>welcome to my website</p>
                    <h1>Hello! I'M <span>{typing}</span></h1>
                    <h2><span>Full-stack</span> developer</h2>
                    <p>Feel free to scroll down and check out what I can offer.</p>
                    <button className="btn btn-warning btn-bg-warning" onClick={goTo}>Contact Me</button>
                </div>
            </header>
        </>
    );
}

export default HomeHeader;