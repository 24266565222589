import React from "react";

function Education({turnProgress}) {
    return (
        <div className="education">
            <div className="progress-title">
                <h3>Education</h3>
                <button className="btn btn-success" onClick={turnProgress}>Experience {"->"}</button>
            </div>
            <ul>
                <li><span>2022</span>
                    <p>Fullstack course at Danilo Vesovic and Rados Mijatovic, (HTML, CSS, JS, React, NodeJS,
                        MongoDB)</p>
                </li>
                <li><span>2021</span>
                    <p>Fullstack course at Slobodan Miric and Danilo Vesovic (HTML, CSS, SASS, JS, PHP, MySQL,
                        WordPress)</p>
                </li>
                <li><span>2021</span>
                    <p>Workshop 30 days of coding at Slobodan Miric (HTML, CSS, SASS, WordPress)</p>
                </li>
                <li>
                    <span>2019</span>
                    <p>
                        Graduate on Technical College of Applied Sciences Urosevac with
                        temporary seat in Leposavic - professional traffic engineer - specialist
                    </p>
                </li>
                <li>
                    <span>2010</span>
                    <p>
                        Graduate on Technical College of Applied Sciences Urosevac with temporary seat in Zvecan -
                        traffic engineer
                    </p>
                </li>
                <li>
                    <span>2003</span>
                    <p>Secondary technical school Mihajlo Petrovic Alas Kosovska Mitrovica - electrical technician of
                        energy</p>
                </li>
            </ul>
        </div>
    );
}

export default Education;
