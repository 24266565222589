import React from 'react';
import "./ctaStyle.scss"


function Cta({goTo}) {
    return (
        <section className="cta">
            <article>
                <h3>Create a Website</h3>
                <p>We can create and develop together.</p>
                <button className="btn btn-danger" onClick={goTo}>Let`s do this!</button>
            </article>
        </section>
    );
}

export default Cta;