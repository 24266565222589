import React from "react";
import "./servicesStyle.scss";

import { FaDesktop } from "react-icons/fa";

const servicesContent = [
  {
    title: "Web Development",
    desc:
      "Web development, also known as website development, refers to the tasks associated with creating, building, and maintaining websites and web applications that run online on a browser. It may, however, also include web design, web programming, and database management.",
  },
  {
    title: "Responsive",
    desc:
      "Responsive Web design is the approach that suggests that design and development should respond to the user’s behavior and environment based on screen size, platform and orientation. Almost every new client these days wants on first place a mobile version of their website and then for others screen size. ",
  },

  {
    title: "User friendly",
    desc:
      "Having a strong Internet presence is necessary. There is a simple truth when it comes to website success – if you want people to use your site, you need to make that site easy to use. You want to grow and attract new customers, but if your site is not user-friendly then you’re doing your business more harm than good.",
  },
];

function Services() {
  return (
    <section className="services container">
      <h2>What I Do</h2>
      <article>
        {servicesContent.map((el, index) => {
          return (
            <div key={index}>
              <FaDesktop />
              <h3>{el.title}</h3>
              <p>{el.desc}</p>
            </div>
          );
        })}
      </article>
    </section>
  );
}

export default Services;
