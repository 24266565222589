import React, { useEffect, useMemo, useState } from "react";
import Education from "./Education";
import Experience from "./Experience";
import "./progress.scss";

const slideLeft = {
  animationName: "moveLeft",
};
const slideRight = {
  animationName: "moveRight",
};

function Progress() {
  const [switchSlide, setSwitchSlide] = useState(null);
  const [isSwitched, setIsSwitched] = useState(false);

  const switchStyle = useMemo(() => {
    if (!isSwitched) {
      setIsSwitched(true);
      return null;
    } else {
      if (switchSlide) {
        return slideLeft;
      } else {
        return slideRight;
      }
    }
  }, [switchSlide]);

  const changeSlide = () => {
    setSwitchSlide(!switchSlide);
  };

  return (
    <section className="progress-wrapper">
      <article className="container">
        <h2>Progress</h2>
      </article>
      <article className="container content">
        <div
          style={switchStyle ? { ...switchStyle } : null}
          className="slide-progress"
        >
          <Education turnProgress={changeSlide} />
          <Experience turnProgress={changeSlide} />
        </div>
      </article>
    </section>
  );
}

export default Progress;
