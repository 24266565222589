import axios from "axios";

class AdminService {
    static uploadImage = (body, id) => {
        return axios.post("api/users/upload/" + id, body);
    };

    static getUserInfo = (id) => {
        return axios.get("api/users/findUser");
    };

    static updateUserInfo = (body) => {
        return axios.post("api/users/update", body);
    };

    static addProject = (body) => {
        return axios.post("api/project/add", body)
    }
}

export default AdminService;
