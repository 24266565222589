import React, {useEffect, useState} from 'react';
import ProjectService from "../../services/projectService";

function Services() {
    const [img, setImg] = useState("");
    useEffect(() => {

    })

    return (
        <div><h3>Services</h3>


        </div>
    );
}

export default Services;