import React, {useEffect, useMemo} from "react";
import {Outlet, NavLink} from "react-router-dom";
import adminRoutes from "../../config/adminRoutes";
import "./dashboard.scss";

function Dashboard() {

    const navigationLayout = useMemo(() => {
        let layout = [];
        for (const key in adminRoutes) {
            layout.push(
                <li key={layout.length}>
                    <NavLink to={adminRoutes[key].route}>{adminRoutes[key].name}</NavLink>
                </li>
            );
        }
        return layout;
    }, []);

    return (
        <section className="dashboard container">
            <article>
                <aside>
                    <ul>{navigationLayout}</ul>
                </aside>
                <div>
                    <Outlet/>
                </div>
            </article>
        </section>
    );
}

export default Dashboard;
