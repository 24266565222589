import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../store/userSlice";
import AdminService from "../../services/adminService";
import UtilsService from "../../services/utilsService";
import Modal from "../../components/Modal/Modal";
import EditFrom from "./EditFrom";
import {toast, ToastContainer} from "react-toastify";
import "./info.scss";
import {setShowModal} from "../../store/modalSlice";

function Info() {
    const {user} = useSelector((state) => state.userStore);
    const {showModal} = useSelector((state) => state.modalStore);
    const [profileImage, setProfileImage] = useState();
    const fileRef = useRef();
    const dispatch = useDispatch();

    const handleFile = (e) => {
        setProfileImage(e.target.files[0]);
    };

    const handleUpload = () => {
        if (fileRef.current.value !== "") {
            let fd = new FormData();
            fd.append("profileImage", profileImage);
            fd.append("oldImage", user.profileImage);
            AdminService.uploadImage(fd, user._id).then((res) => {
                dispatch(setUser(res.data));
                fileRef.current.value = "";
            });
        } else {
            toast.warning("You must choose a image!");
        }
    };

    return (
        <div className="userInfo-wrapper">
            <div className="dashboard-title">
                <h3>My info</h3>
                <div className="dashboard-title-btn">
                    <button
                        className="btn btn-success"
                        onClick={() => dispatch(setShowModal())}
                    > Edit info
                    </button>
                    <button
                        className="btn btn-warning"
                        onClick={() => fileRef.current.click()}
                    >
                        Set image
                    </button>
                    <input ref={fileRef} type="file" onInput={handleFile}/>
                    <button className="btn btn-danger" onClick={handleUpload}>
                        Upload image
                    </button>
                </div>
            </div>
            <div className="dashboard-content">

                <div className="profile-image">
                    {user.profileImage && (
                        <img
                            src={UtilsService.realFileURL(user.profileImage)}
                            alt={user.profileImage}
                        />
                    )}
                </div>
                <ul className="profile-info">
                    <li>
                        <span>Full name:</span> {user.first_name} {user.last_name}
                    </li>
                    <li>
                        <span>Email:</span> {user.email}
                    </li>
                    <li>
                        <span>Age:</span> {user.age} year
                    </li>
                    <li>
                        <span>Experience:</span> {user.experience} year
                    </li>
                    <li>
                        <span>About me:</span> <p>{user.about}</p>
                    </li>

                </ul>
            </div>
            <ToastContainer/>
            {/*Modal form for edit data*/}

            {showModal &&
                <Modal>
                    <EditFrom user={user}/>
                </Modal>}

        </div>
    );
}

export default Info;
