import React, { useEffect } from "react";
import UtilsService from "../../services/utilsService";
import { FaGithub, FaGlobe, FaStar } from "react-icons/fa";
import ProjectService from "../../services/projectService";
import { useDispatch } from "react-redux";
import { deleteProject, updateProject } from "../../store/projectSlice";
import { setMounted, setShowModal } from "../../store/modalSlice";

function ProjectCard({ project, showEditForm }) {
  const dispatch = useDispatch();
  const onClickDelete = () => {
    ProjectService.delete(project._id).then((res) => {
      if (res) {
        dispatch(deleteProject(project._id));
      }
    });
  };

  const onClickEdit = () => {
    showEditForm(project);
    dispatch(setShowModal(true));
  };
  const setFavorite = () => {
    let copyProject = { ...project };
    let fd = new FormData();
    copyProject.favorite = !project.favorite;
    fd.append("data", JSON.stringify(copyProject));
    ProjectService.update(fd).then((res) => {
      dispatch(updateProject(copyProject));
    });
  };

  return (
    <div className="project-card">
      <div className="thumbnail">
        <img src={UtilsService.realFileURL(project.thumbnail)} alt="" />
      </div>
      <div className="project-card-body">
        <span
          style={project.favorite ? { color: "yellow" } : { color: "white" }}
          onClick={setFavorite}
        >
          <FaStar />
        </span>
        <div className="project-card-text">
          <h4>{project.project_title}</h4>
          <ul>
            {project.technology.map((el, index) => (
              <li key={index}>{el}</li>
            ))}
          </ul>
          <p>{project.description}</p>
        </div>

        <div className="project-card-btn">
          {project.github_url && (
            <a href={project.github_url}>
              <FaGithub />
            </a>
          )}
          {project.live_url && (
            <a href={project.live_url}>
              <FaGlobe />
            </a>
          )}
          <button className="btn btn-sm btn-danger" onClick={onClickDelete}>
            Delete
          </button>
          <button className="btn btn-sm btn-warning" onClick={onClickEdit}>
            Edit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
