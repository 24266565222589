import {createSlice} from "@reduxjs/toolkit";

const projectSlice = createSlice({
    name: "project",
    initialState: {
        projects: []
    },
    reducers: {
        setProject: (state, action) => {
            state.projects = action.payload
        },
        deleteProject: (state, action) => {
            // let copyProject = {...state.projects}
            state.projects = state.projects.filter((el) => el._id !== action.payload)
        },
        updateProject: (state, action) => {
            let {payload} = action
            state.projects.find((el, index) => {
                if (el._id === payload._id) {
                    state.projects[index] = payload
                    return el._id === payload._id
                }
            })
        }
    }
})


export const {setProject, deleteProject, updateProject} = projectSlice.actions
export default projectSlice.reducer