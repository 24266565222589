import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useDelayUnmount} from "../../hooks/useDelayUnmount";
import "./modal.scss";
import {setCloseModal, setMounted} from "../../store/modalSlice";

function Modal({children}) {
    const {isMounted, showModal} = useSelector((state) => state.modalStore);
    const dispatch = useDispatch()
    const shouldRender = useDelayUnmount(isMounted, 300)

    const mountedStyle = {animation: "zoomIn 400ms linear"};
    const unmountedStyle = {animation: "zoomOut 310ms linear"};

    useEffect(() => {
        dispatch(setMounted(true))
        window.addEventListener("wheel", preventScroll, {passive: false});
        window.addEventListener("keydown", closeModal)
        return () => {
            window.removeEventListener("wheel", preventScroll);
            window.removeEventListener("keydown", closeModal);
        };
    }, []);

    useEffect(() => {
        if (!shouldRender && !isMounted) {
            dispatch(setCloseModal())
        }
    }, [shouldRender, showModal]);

    const preventScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    const closeModal = (e) => {
        if (e.key === "Escape") {
            dispatch(setMounted(false))
        }
    }

    return (
        <div className="modal-wrapper">
            {shouldRender && <div
                style={isMounted ? mountedStyle : unmountedStyle}
                className="modal-content"
            >
                {children}
            </div>}

        </div>
    );
}

export default Modal;
