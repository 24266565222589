import {createSlice} from "@reduxjs/toolkit";

const ModalSlice = createSlice({
    name: "modal",
    initialState: {
        isMounted: false,
        showModal: false
    },
    reducers: {
        setShowModal: (state, action) => {
            state.showModal = true;
            state.isMounted = true
        },

        setCloseModal: (state, action) => {
            state.showModal = false;
            state.isMounted = false
        },

        setMounted: (state, action) => {
            state.isMounted = action.payload
        }
    },
});

export const {setShowModal, setCloseModal, setMounted} = ModalSlice.actions;
export default ModalSlice.reducer;
