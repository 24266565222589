import {configureStore} from "@reduxjs/toolkit";
import modalSlice from "./modalSlice";
import userSlice from "./userSlice";
import projectSlice from "./projectSlice";
import skillSlice from "./skillSlice";

const store = configureStore({
	reducer: {
		userStore: userSlice,
		modalStore: modalSlice,
		projectStore: projectSlice,
		skillsStore: skillSlice
	},
});

export default store;
