import React from 'react';
import flash from "../../assets/flash-light.png"
import {useNavigate} from "react-router-dom";
import "./errorPage.scss"

function ErrorPage() {
    const redirect = useNavigate()
    return (

        <div className="error-wrapper">
            <div className="error-animation">
                <div className="text">
                    <h2>404</h2>
                    <p>Page not found</p>
                </div>
                <div className="flash-light">
                    <div className="light"></div>
                    <div className="ray"></div>
                    <img src={flash} alt=""/>
                </div>
                <button className="btn btn-warning" onClick={() => {
                    redirect("/")
                }}>GO HOME
                </button>
            </div>
        </div>


    );
}

export default ErrorPage;