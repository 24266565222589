import React from "react";

function Experience({turnProgress}) {
    return (
        <div className="experience">
            <div className="progress-title">
                <button className="btn btn-success" onClick={turnProgress}>{"<-"} Education</button>
                <h3>Experience</h3>
            </div>
            <ul>
                <li>
                    <span>2007 - and now:</span>
                    <p>
                        Permanently employed at the Academy of Applied Studies of Kosovo and
                        Metohija - Department Uroševac - Leposavić as a senior technical
                        associate.
                    </p>
                </li>
                <li>MS Word practical exercise demonstrator</li>
                <li>MS Excel practical exercise demonstrator</li>
                <li>MS Access practical exercise demonstrator</li>
                <li>AutoCAD practical exercise demonstrator</li>
                <li>CorelDRAW practical exercise demonstrator</li>
                <li>Administration Moodle DLS</li>
                <li>Develop of the electronic Register of students</li>
                <li>Computer processing and preparation of the book for printing</li>
            </ul>
        </div>
    );
}

export default Experience;
