import React, { useEffect, useRef, useState } from "react";
import "./skills.scss";
import SkillService from "../../services/skillService";
import UtilsService from "../../services/utilsService";
import { useDispatch, useSelector } from "react-redux";
import { addSkill, setSkills, updateSkill } from "../../store/skillSlice";
import { toast, ToastContainer } from "react-toastify";

const initInputData = { name: "", icon: "", state: null };

function Skills() {
  const dispatch = useDispatch();
  const { skills } = useSelector((state) => state.skillsStore);
  const [inputData, setInputData] = useState(initInputData);
  const [selectedSkill, setSelectedSkill] = useState({});
  const refFileInput = useRef();

  useEffect(() => {
    SkillService.getAll().then((res) => {
      dispatch(setSkills(res.data));
    });
  }, []);

  const onInputHandler = (e) => {
    let copyInputData = { ...inputData };
    copyInputData[e.target.name] = e.target.value;
    setInputData(copyInputData);
  };

  const onFilesHandler = (e) => {
    let copyInputData = { ...inputData };
    copyInputData[e.target.name] = e.target.files[0];
    setInputData(copyInputData);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    let fd = new FormData();
    for (const key in inputData) {
      fd.append(key, inputData[key]);
    }
    if (Object.keys(selectedSkill).length === 0) {
      if (inputData.icon) {
        SkillService.addSkill(fd).then((res) => {
          dispatch(addSkill({ ...inputData, icon: res.data }));
        });
      } else {
        toast.warning("You must choose icon or select skill for edit!");
      }
    } else {
      SkillService.updateSkill(fd).then((res) => {
        dispatch(updateSkill({ ...inputData, icon: res.data }));
        selectedSkill.classList.remove("selected");
        setSelectedSkill({});
      });
    }
    setInputData(initInputData);
    refFileInput.current.value = null;
  };

  const onClickHandler = (e, skill) => {
    let currentBox = e.currentTarget;
    if (Object.keys(selectedSkill).length === 0) {
      currentBox.classList.add("selected");
    } else {
      selectedSkill.classList.remove("selected");
      currentBox.classList.add("selected");
    }
    setSelectedSkill(currentBox);
    setInputData({ ...skill, oldIcon: skill.icon });
  };

  const onClickBtnHandler = () => {
    console.log(Object.keys(selectedSkill));
    console.log(inputData);
    if (Object.keys(selectedSkill).length > 0) {
      console.log("SELECTED");
      toast.success("Succeffuly delete skill.");
    } else {
      toast.warning("If you want delete skill first must select skill!");
    }
  };
  return (
    <div className="adminSkills-wrapper">
      <div className="dashboard-title">
        <h3>Skills</h3>
      </div>
      <div className="dashboard-content">
        <form className="form" onSubmit={onSubmitHandler}>
          <div className="form-group">
            <input
              id="skillName"
              type="text"
              placeholder=""
              name="name"
              onInput={onInputHandler}
              value={inputData.name}
            />
            <label htmlFor="skillName">Skill</label>
          </div>
          <div className="form-group">
            <input
              id="skillPrimary"
              type="radio"
              name="priority"
              value="primary"
              onChange={onInputHandler}
              checked={inputData.priority === "primary"}
            />
            <label htmlFor="skillPrimary">Primary</label>
          </div>
          <div className="form-group">
            <input
              id="skillSecondary"
              type="radio"
              name="priority"
              value="secondary"
              onChange={onInputHandler}
              checked={inputData.priority === "secondary"}
            />
            <label htmlFor="skillSecondary">Secondary</label>
          </div>
          <label
            htmlFor="skillIcon"
            className="btn btn-sm btn-success btn-bg-success"
          >
            Choose Icon
          </label>
          <input
            id="skillIcon"
            type="file"
            name="icon"
            onInput={onFilesHandler}
            ref={refFileInput}
          />
          <button className="btn btn-sm btn-warning btn-bg-warning">
            Add Skill
          </button>

          <button
            type="button"
            className="btn btn-sm btn-danger btn-bg-danger"
            onClick={onClickBtnHandler}
          >
            Delete
          </button>
        </form>

        <h4>Primary skill</h4>
        <div className="skills-wrapper primary">
          {skills.primary?.length > 0 &&
            skills.primary.map((el, index) => {
              return (
                <div
                  className="skill"
                  key={index}
                  onClick={(e) => {
                    onClickHandler(e, el);
                  }}
                >
                  <div>
                    <img src={UtilsService.realFileURL(el.icon)} alt="" />
                  </div>
                  <h5>{el.name}</h5>
                </div>
              );
            })}
        </div>
        <h4>Secondary skill</h4>
        <div className="skills-wrapper secondary">
          {skills.secondary?.length > 0 &&
            skills.secondary.map((el, index) => {
              return (
                <div
                  className="skill"
                  key={index}
                  onClick={(e) => {
                    onClickHandler(e, el);
                  }}
                >
                  <div>
                    <img src={UtilsService.realFileURL(el.icon)} alt="" />
                  </div>
                  <h5>{el.name}</h5>
                </div>
              );
            })}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Skills;
