import React, {useState} from 'react';
import {FaRegEnvelope, FaMobileAlt, FaMapMarkerAlt} from "react-icons/fa"
import "./homeContactStyle.scss"
import {MailService} from "../../services/mailService";

function HomeContact({scrollTo}) {
    const [inputData, setInputData] = useState({});

    function onInputHandler(e) {
        let copyInputData = {...inputData}
        copyInputData[e.target.name] = e.target.value
        setInputData(copyInputData)
    }

    function onSubmitHandler(e) {
        e.preventDefault()
        MailService.sendMail(inputData).then(res => {
            console.log(res.data)
        })
    }

    return (
        <section ref={scrollTo} className="homeContact">
            <article className="container">
                <h2>Get in touch</h2>
                <p>Do you have any question for me? Would you like to work with me? Contact me!</p>
                <div className="contact-info">
                    <div className="email">
                        <FaRegEnvelope/>
                        <h3>E-mail:</h3>
                        <h4><a href="mailto:zile028@gmail.com">zile028@gmail.com</a></h4>
                    </div>
                    <div className="phone">
                        <FaMobileAlt/>
                        <h3>Call:</h3>
                        <h4><a href="tel:+381606374003">+381 60 637 40 03</a></h4>
                    </div>
                    <div className="location">
                        <FaMapMarkerAlt/>
                        <h3>Location</h3>
                        <h4>Kosovska Mitrovica, Serbia</h4>
                    </div>
                </div>
                <div className="contact-form">
                    <form className="form" onSubmit={onSubmitHandler}>
                        <div className="form-name form-group">
                            <input type="text" id="name" name="name" placeholder="" onInput={onInputHandler}/>
                            <label htmlFor="name">What is your name?</label>
                        </div>
                        <div className="form-email form-group">
                            <input type="email" id="email" name="email" placeholder="" onInput={onInputHandler}/>
                            <label htmlFor="email">Your email address?</label>
                        </div>
                        <div className="form-subject form-group">
                            <input type="text" id="subject" name="subject" placeholder="" onInput={onInputHandler}/>
                            <label htmlFor="subject">Subject:</label>
                        </div>
                        <div className="form-message form-group">
                            <textarea name="message" id="message" placeholder=""
                                      onInput={onInputHandler}></textarea>
                            <label htmlFor="message">How can I help you?</label>
                        </div>
                        <div className="form-btn">
                            <button className="btn btn-danger">Send message</button>
                        </div>
                    </form>
                </div>
            </article>
        </section>
    );
}

export default HomeContact;