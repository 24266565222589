import React from 'react';
import Social from "../Social/Social";
import "./footerStyle.scss"

function Footer() {
    return (
        <footer>
            <Social/>
            <p>&copy; 2022 Created & Built by <a href="https://zile028.com">Zile</a></p>
            <p>All Rights Reserved</p>
        </footer>
    );
}

export default Footer;