import axios from "axios";
import "./App.css";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Navigate } from "react-router";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import Login from "./pages/Login/Login";
import Navigation from "./components/Navigation/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { login, setUser } from "./store/userSlice";
import Dashboard from "./pages/Dashboard/Dashboard";
import Info from "./adminComponents/Info/Info";
import Services from "./adminComponents/Services/Services";
import Project from "./adminComponents/Project/Project";
import UserService from "./services/userService";
import ProjectService from "./services/projectService";
import { setProject } from "./store/projectSlice";
import adminRoutes from "./config/adminRoutes";
import Skills from "./adminComponents/Skills/Skills";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

axios.defaults.baseURL = "https://be-portfolio.zile028.com";
axios.interceptors.request.use(
  (config) => {
    if (localStorage.hasOwnProperty("token")) {
      config.headers.auth = localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function App() {
  const dispatch = useDispatch();
  const [checkIsLogged, setCheckIsLogged] = useState(false);

  useEffect(() => {
    handleUserLogged();
    handleGetUserInfo();
    handleGetFromDB();
  }, []);

  const handleGetFromDB = () => {
    UserService.getUserInfo().then((res) => dispatch(setUser(res.data)));
    ProjectService.getAll().then((res) => dispatch(setProject(res.data)));
  };
  const handleUserLogged = () => {
    let userLogin = localStorage.getItem("userLogin");
    if (localStorage.hasOwnProperty("userLogin") && userLogin !== "undefined") {
      userLogin && dispatch(login(JSON.parse(userLogin)));
    }
    setCheckIsLogged(true);
  };
  const handleGetUserInfo = () => {
    UserService.getUserInfo().then((res) => dispatch(setUser(res.data)));
  };

  return (
    <div className="App">
      <Navigation />
      {checkIsLogged && (
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"login"} element={<Login />} />

          {/*{admin part}*/}
          <Route
            path={adminRoutes.DASHBOARD.route}
            element={
              <AdminProtect>
                <Dashboard />
              </AdminProtect>
            }
          >
            <Route index exact={true} element={<Info />} />
            <Route path={adminRoutes.SERVICES.route} element={<Services />} />
            <Route path={adminRoutes.PROJECTS.route} element={<Project />} />
            <Route path={adminRoutes.SKILLS.route} element={<Skills />} />
          </Route>

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      )}
      <Footer />
    </div>
  );
}

function AdminProtect({ children }) {
  const { isLogin } = useSelector((state) => state.userStore);
  if (isLogin) {
    return { ...children };
  } else {
    return <Navigate to={"/"} />;
  }
}

export default App;
